<template xmlns:van-cell="http://www.w3.org/1999/xlink">
  <div>
    <NavBar :back="false">订单详情</NavBar>
    <div class="topBG"></div>

    <div class="frame">
      <div class="frame-title-l">{{ orderData.proName }}</div>
      <div class="frame-body">
        <van-cell title="景区订单号" :value="orderData.suppOrderId" v-if="orderData.suppOrderId"/>
        <van-cell title="订单号" :value="sign.orderNum" v-else/>
        <van-cell title="入园站点" :value="orderData.stationName"/>
        <van-cell title="入园日期" :value="orderData.playTime"/>
        <van-cell title="首次入园时间" :value="orderData.timeWindowName"/>
        <van-cell title="数量" :value="orderData.qty"/>
        <!--        <van-collapse v-model="activeNames">-->
        <!--          <van-collapse-item title="更多信息" name="more">-->
        <!--            <van-cell title="联系人" :value="orderData.userName"/>-->
        <!--            <van-cell title="联系电话" :value="orderData.userPhone"/>-->
        <!--            <van-cell title="下单时间" :value="orderData.createTime"/>-->
        <!--            <van-cell title="支付时间" :value="orderData.payTime"/>-->
        <!--          </van-collapse-item>-->
        <!--        </van-collapse>-->
      </div>
    </div>
    <div class="frame">
      <div class="frame-title-s" style="color: #ee0a24">注意事项</div>
      <div class="remark" style="color: #000000" v-if="orderData.remark">{{ orderData.remark }}</div>
      <div class="remark">
        <div v-if="orderData.haveDiscount">*全日制大学本科及以下学历在校学生和残疾人需上传本人有效证件线上审核，审核通过后方可入园。</div>
        <div>*未携带身份证原件的，可进行人脸采集，采集完成后可直接刷脸入园。</div>
        <div>*注意：人脸采集功能仅支持曾在公安局办理过身份证的人群，从未办理过身份证的小朋友需前往售票窗口采集人脸。</div>
      </div>
    </div>

    <div class="frame">
      <div class="frame-title-s">游客列表</div>
      <div class="ticketList">
        <div class="playerItem row-between-center"
             v-for="(player,index) in orderData.players" :key="player.ticketNo"
             @click="player.status===0?(visited=index):null">
          <div>
            <div class="playerName row-start-center">{{ player.userName }}
              <div class="status" :style="statusList[player.status].style">{{ statusList[player.status].text }}</div>
            </div>
            <div class="playerIdNum">{{ player.userId }}</div>
          </div>
          <!--          <transition name="btn">-->
          <div class="tran row-center-center" v-if="player.status === 0">
            <div class="playerBtn row-center-center" @click="selectImg(index)" v-if="player.needCardFlag === 1"
                 :style="cardStatus[player.cardStatus].style">
              <svg t="1642495543506" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="17015" width=".6rem" height=".6rem">
                <path
                    fill="#ffffff"
                    d="M810.666667 938.666667H213.333333c-72.533333 0-128-55.466667-128-128V213.333333c0-72.533333 55.466667-128 128-128h597.333334c72.533333 0 128 55.466667 128 128v597.333334c0 72.533333-55.466667 128-128 128zM213.333333 170.666667c-25.6 0-42.666667 17.066667-42.666666 42.666666v597.333334c0 25.6 17.066667 42.666667 42.666666 42.666666h597.333334c25.6 0 42.666667-17.066667 42.666666-42.666666V213.333333c0-25.6-17.066667-42.666667-42.666666-42.666666H213.333333z"
                    p-id="17016"></path>
                <path
                    fill="#ffffff"
                    d="M362.666667 469.333333C302.933333 469.333333 256 422.4 256 362.666667S302.933333 256 362.666667 256 469.333333 302.933333 469.333333 362.666667 422.4 469.333333 362.666667 469.333333z m0-128c-12.8 0-21.333333 8.533333-21.333334 21.333334s8.533333 21.333333 21.333334 21.333333 21.333333-8.533333 21.333333-21.333333-8.533333-21.333333-21.333333-21.333334zM213.333333 938.666667c-12.8 0-21.333333-4.266667-29.866666-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l469.333333-469.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0l213.333334 213.333333c17.066667 17.066667 17.066667 42.666667 0 59.733334s-42.666667 17.066667-59.733334 0L682.666667 486.4 243.2 925.866667c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z"
                    p-id="17017"></path>
              </svg>
              <input :ref="'img'+index" style="display: none" type="file" accept="image/jpeg"
                     @change="getImg(index)"/>
              {{ cardStatus[player.cardStatus].text }}
            </div>
            <div class="playerBtn row-center-center" :style="faceStatus[player.faceStatus].style"
                 @click="goFace(player)" v-if="!player.photoData">
              <svg t="1642495683623" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="19808" width=".6rem" height=".6rem">
                <path
                    d="M170.666667 298.666667a42.666667 42.666667 0 1 1-85.333334 0V256a170.666667 170.666667 0 0 1 170.666667-170.666667h42.666667a42.666667 42.666667 0 1 1 0 85.333334H256a85.333333 85.333333 0 0 0-85.333333 85.333333v42.666667zM85.333333 725.333333a42.666667 42.666667 0 0 1 85.333334 0v42.666667a85.333333 85.333333 0 0 0 85.333333 85.333333h42.666667a42.666667 42.666667 0 0 1 0 85.333334H256a170.666667 170.666667 0 0 1-170.666667-170.666667v-42.666667zM938.666667 298.666667a42.666667 42.666667 0 0 1-85.333334 0V256a85.333333 85.333333 0 0 0-85.333333-85.333333h-42.666667a42.666667 42.666667 0 0 1 0-85.333334h42.666667a170.666667 170.666667 0 0 1 170.666667 170.666667v42.666667z m-85.333334 426.666666a42.666667 42.666667 0 0 1 85.333334 0v42.666667a170.666667 170.666667 0 0 1-170.666667 170.666667h-42.666667a42.666667 42.666667 0 0 1 0-85.333334h42.666667a85.333333 85.333333 0 0 0 85.333333-85.333333v-42.666667zM307.2 622.933333a42.666667 42.666667 0 1 1 68.266667-51.2c34.602667 46.165333 78.848 68.266667 136.533333 68.266667s101.930667-22.101333 136.533333-68.266667a42.666667 42.666667 0 0 1 68.266667 51.2C666.069333 690.56 596.522667 725.333333 512 725.333333c-84.522667 0-154.069333-34.773333-204.8-102.4zM341.333333 384a42.666667 42.666667 0 1 1 85.333334 0v42.666667a42.666667 42.666667 0 1 1-85.333334 0V384z m256 0a42.666667 42.666667 0 0 1 85.333334 0v42.666667a42.666667 42.666667 0 0 1-85.333334 0V384z"
                    fill="#ffffff" p-id="19809"></path>
              </svg>
              {{ faceStatus[player.faceStatus].text }}
            </div>
          </div>
          <!--          </transition>-->
          <!--          <transition name="status">-->
          <!--            <div class="tran statusBox row-center-center" v-if="visited!== index">-->
          <!--              <svg t="1642495543506" class="icon" viewBox="0 0 1024 1024" version="1.1" v-if="player.needCardFlag === 1"-->
          <!--                   xmlns="http://www.w3.org/2000/svg" p-id="17015" width=".6rem" height=".6rem">-->
          <!--                <path-->
          <!--                    fill="#ee0a24"-->
          <!--                    d="M810.666667 938.666667H213.333333c-72.533333 0-128-55.466667-128-128V213.333333c0-72.533333 55.466667-128 128-128h597.333334c72.533333 0 128 55.466667 128 128v597.333334c0 72.533333-55.466667 128-128 128zM213.333333 170.666667c-25.6 0-42.666667 17.066667-42.666666 42.666666v597.333334c0 25.6 17.066667 42.666667 42.666666 42.666666h597.333334c25.6 0 42.666667-17.066667 42.666666-42.666666V213.333333c0-25.6-17.066667-42.666667-42.666666-42.666666H213.333333z"-->
          <!--                    p-id="17016"></path>-->
          <!--                <path-->
          <!--                    fill="#ee0a24"-->
          <!--                    d="M362.666667 469.333333C302.933333 469.333333 256 422.4 256 362.666667S302.933333 256 362.666667 256 469.333333 302.933333 469.333333 362.666667 422.4 469.333333 362.666667 469.333333z m0-128c-12.8 0-21.333333 8.533333-21.333334 21.333334s8.533333 21.333333 21.333334 21.333333 21.333333-8.533333 21.333333-21.333333-8.533333-21.333333-21.333333-21.333334zM213.333333 938.666667c-12.8 0-21.333333-4.266667-29.866666-12.8-17.066667-17.066667-17.066667-42.666667 0-59.733334l469.333333-469.333333c17.066667-17.066667 42.666667-17.066667 59.733333 0l213.333334 213.333333c17.066667 17.066667 17.066667 42.666667 0 59.733334s-42.666667 17.066667-59.733334 0L682.666667 486.4 243.2 925.866667c-8.533333 8.533333-17.066667 12.8-29.866667 12.8z"-->
          <!--                    p-id="17017"></path>-->
          <!--              </svg>-->
          <!--              <svg t="1642495683623" class="icon" viewBox="0 0 1024 1024" version="1.1" v-if="!player.photoData"-->
          <!--                   xmlns="http://www.w3.org/2000/svg" p-id="19808" width=".6rem" height=".6rem">-->
          <!--                <path-->
          <!--                    d="M170.666667 298.666667a42.666667 42.666667 0 1 1-85.333334 0V256a170.666667 170.666667 0 0 1 170.666667-170.666667h42.666667a42.666667 42.666667 0 1 1 0 85.333334H256a85.333333 85.333333 0 0 0-85.333333 85.333333v42.666667zM85.333333 725.333333a42.666667 42.666667 0 0 1 85.333334 0v42.666667a85.333333 85.333333 0 0 0 85.333333 85.333333h42.666667a42.666667 42.666667 0 0 1 0 85.333334H256a170.666667 170.666667 0 0 1-170.666667-170.666667v-42.666667zM938.666667 298.666667a42.666667 42.666667 0 0 1-85.333334 0V256a85.333333 85.333333 0 0 0-85.333333-85.333333h-42.666667a42.666667 42.666667 0 0 1 0-85.333334h42.666667a170.666667 170.666667 0 0 1 170.666667 170.666667v42.666667z m-85.333334 426.666666a42.666667 42.666667 0 0 1 85.333334 0v42.666667a170.666667 170.666667 0 0 1-170.666667 170.666667h-42.666667a42.666667 42.666667 0 0 1 0-85.333334h42.666667a85.333333 85.333333 0 0 0 85.333333-85.333333v-42.666667zM307.2 622.933333a42.666667 42.666667 0 1 1 68.266667-51.2c34.602667 46.165333 78.848 68.266667 136.533333 68.266667s101.930667-22.101333 136.533333-68.266667a42.666667 42.666667 0 0 1 68.266667 51.2C666.069333 690.56 596.522667 725.333333 512 725.333333c-84.522667 0-154.069333-34.773333-204.8-102.4zM341.333333 384a42.666667 42.666667 0 1 1 85.333334 0v42.666667a42.666667 42.666667 0 1 1-85.333334 0V384z m256 0a42.666667 42.666667 0 0 1 85.333334 0v42.666667a42.666667 42.666667 0 0 1-85.333334 0V384z"-->
          <!--                    fill="#ee0a24" p-id="19809"></path>-->
          <!--              </svg>-->
          <!--              <div :style="statusList[player.status].style">{{ statusList[player.status].text }}</div>-->
          <!--            </div>-->
          <!--          </transition>-->
        </div>
      </div>
    </div>
    <!--    <div class="notice center" v-if="showNotice">-->
    <!--      <div class="noticeBar">-->
    <!--        <div class="noticeTitle center">注意事项</div>-->
    <!--        <div class="noticeContent">{{ orderData.remark }}</div>-->
    <!--        <div class="noticeConfirm center" @click="hideNotice">我已知晓</div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "OrderDetail",
  computed: {
    orderData() {
      let orderData = this.$store.state.orderData
      let haveDiscount = false
      if (orderData) {
        for (let player of orderData.players) {
          haveDiscount = haveDiscount||player.needCardFlag === 1
          orderData.haveDiscount = haveDiscount
          if (player.userIdcard.length < 10) {
            player.userId = player.userIdcard.slice(0, 4) + '****' + player.userIdcard.slice(-2)
          } else {
            player.userId = player.userIdcard.slice(0, 6) + '****' + player.userIdcard.slice(-4)
          }
        }
      }
      return orderData;
    },
    sign() {
      return this.$store.state.sign
    },
    showNotice() {
      return this.$store.state.showNotice && (!this.$store.state.hideNotice)
    }
  },
  watch: {
    // $route:function () {
    // },
    // '$route':{
    //   handler(val,oldval){
    //     console.log(val,oldval)
    //     this.getOrder()
    //   },
    //   deep:true
    // },
    orderData: {
      handler(value) {
        if (value.remark) {
          this.$store.commit('setShowNotice', true)
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      banBtn: true,
      activeNames: [],
      visited: -1,
      statusList: [{text: '待检票', style: 'color: rgb(0,180,83);'},
        {text: '已检票', style: 'color: rgb(238,10,36);'},
        {text: '退票中', style: 'color: rgb(238,10,36);'},
        {text: '已退票', style: 'color: rgb(128,128,128)'},
        {text: '已取消', style: 'color: rgb(128,128,128);'},
        {text: '未支付', style: 'color: rgb(128,128,128);'}],
      cardStatus: [{text: '上传优惠证件', style: 'background-color: rgba(0,180,83,1);'},
        {text: '优惠证件审核中', style: 'background-color: rgba(238,10,36,0.35);'},
        {text: '优惠证件已通过', style: 'background-color: rgba(0,180,83,0.35);'},
        {text: '优惠证件不通过', style: 'background-color: rgba(238,10,36,1);'}],
      faceStatus: [{text: '人脸采集', style: 'background-color: rgba(0,180,83,1);'},
        {text: '采集完成', style: 'background-color: rgba(0,180,83,0.5);'}]
    }
  },
  methods: {
    getOrder() {
      this.api.getOrderByCode(this.sign).then(apiRes => {
        if (apiRes.success) {
          this.$store.commit('setOrderData', apiRes.data)
        }
      })
    },
    selectImg(index) {
      if (this.orderData.players[index].cardStatus === 0 || this.orderData.players[index].cardStatus === 3) {
        this.$refs['img' + index][0].dispatchEvent(new MouseEvent('click'))
      }
    },
    getImg(index) {
      if(this.banBtn){
        this.banBtn = false
        this.$toast.loading('正在提交')
        let img = this.$refs['img' + index][0].files[0]
        let reader = new FileReader()
        reader.readAsDataURL(img)
        reader.onloadend = (data) => {
          let bs64 = data.target.result
          let sta = bs64.indexOf('base64')
          this.api.uploadIdcard({
            orderNum: this.sign.orderNum,
            sign: this.sign.sign,
            idcard: this.orderData.players[index].userIdcard,
            data: bs64.slice(sta + 7)
          }).then(apiRes => {
            this.banBtn = true
            if (apiRes.success) {
              this.$toast.success('优惠证件提交成功，请等待审核')
              this.getOrder()
            } else {
              this.$toast.fail(apiRes.msg)
              this.getOrder()
            }
          })
        }
      }
    },
    goFace(e) {
      if (e.faceStatus === 0) {
        this.$store.commit('setPlayer', e)
        this.$router.push('/face')
      }
    },
    hideNotice() {
      this.$store.commit('setHideNotice', true)
    }
  }
}
</script>

<style scoped>
.topBG {
  position: fixed;
  top: 2.2rem;
  left: 0;
  right: 0;
  height: 16rem;
  background: linear-gradient(to bottom, #00b453, #F2F2F2);
  z-index: -1;
}

.frame {
  width: 15rem;
  margin: .5rem .5rem 0 .5rem;
  padding: .5rem 0;
  background-color: #FFFFFF;
  border-radius: .5rem;
  z-index: 99;
}

.frame-title-l {
  padding: .2rem .5rem;
  font-size: .7rem;
  font-weight: bold;
  text-align: left;
}

.frame-title-s {
  padding: .2rem .5rem;
  font-size: .6rem;
  font-weight: bold;
  text-align: left;
  color: #2c3e50;
}

.frame-body {
  padding: .3rem .5rem;
}

.playerItem {
  position: relative;
  margin: 0 .5rem;
  padding: .4rem;
  background-color: #f8f8f8;
  border-bottom: solid 1px #fbfbfb;
}

.playerName {
  font-size: .6rem;
  font-weight: bold;
}

.playerIdNum {
  font-size: .5rem;
}

.tran {
  position: absolute;
  right: .4rem;
}

.playerBtn {
  padding: .2rem .4rem;
  margin-left: .2rem;
  font-size: .5rem;
  color: #ffffff;
  /*background-color: rgba(0,180,83,0.5);*/
}

.playerBtn:active {
  background-color: #00b45390;
}

.playerBtn svg {
  padding-right: .1rem;
  /*width: .8rem;*/
  /*height: .8rem;*/
}

.status {
  margin-left: .3rem;
  font-size: .5rem;
}

.statusBox {
  font-size: .6rem;
  font-weight: bold;
  opacity: 1;
}

.statusBox svg {
  margin-right: .2rem;
}

.status-leave-active, .btn-leave-active, .status-enter-active, .btn-enter-active {
  transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;
  -webkit-transition: all 300ms cubic-bezier(0.2, 0.8, 0.8, 0.2) 0ms;
  -moz-transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;
  -o-transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;
}

/*.status-enter-active, .btn-enter-active {*/
/*  transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;*/
/*  -webkit-transition: all 300ms cubic-bezier(0.2, 0.8, 0.8, 0.2) 0ms;*/
/*  -moz-transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;*/
/*  -o-transition: all 300ms cubic-bezier(0.1, 0.8, 0.8, 0.2) 0ms;*/
/*}*/

.status-enter, .status-leave-to {
  opacity: 0;
  /*transform: translateX(2rem);*/
}

.btn-enter, .btn-leave {
  opacity: 0;
  /*transform: translateX(-2rem);*/
}

.remark {
  padding: .2rem .5rem;
  font-size: .5rem;
}

.notice {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 444;
  background-color: #00000030;
}

.noticeBar {
  width: 12rem;
  border-radius: .6rem;
  background-color: #ffffff;
}

.noticeTitle {
  padding: .8rem 0 .3rem 0;
  font-size: .8rem;
  font-weight: bold;
  color: #ee0a24;
  /*border-bottom: solid 1px #f2f2f2;*/
}

.noticeContent {
  padding: .5rem .5rem .8rem .5rem;
  /*letter-spacing: 1px;*/
  font-size: .5rem;
  font-weight: bold;
  line-height: .7rem;
  text-align: center;
}

.noticeConfirm {
  padding: .5rem 0;
  font-size: .8rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #00b453;
  border-bottom-left-radius: .6rem;
  border-bottom-right-radius: .6rem;
}
</style>
